<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from "radix-vue";
import { X } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const props = defineProps<
  DialogContentProps & {
    class?: HTMLAttributes["class"];
    size?: "md" | "lg";
    disableCloseOnOutsideClick?: Boolean;
  }
>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'fixed w-11/12 w-[calc(100%-20px)] left-1/2 top-1/2 max-h-[calc(100%-20px)] overflow-y-auto z-50 grid -translate-x-1/2 -translate-y-1/2 gap-4  bg-background p-6 sm:p-16 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-lg',
          size === 'lg' ? 'max-w-4xl' : 'max-w-xl',
          props.class,
        )
      "
      @pointer-down-outside="
        (event) => {
          if (disableCloseOnOutsideClick) {
            event.preventDefault();
          }
        }
      "
    >
      <slot />

      <DialogClose
        class="absolute right-4 top-4 sm:right-14 sm:top-14 text-mono-light-grey hover:text-mono-black rounded-full w-12 h-12 flex items-center justify-center ring-offset-background transition-all hover:opacity-100 hover:bg-mono-off-white hover:rotate-180 focus-visible:rotate-180 font-bold focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
      >
        <X class="w-6 h-6 stroke-[2px] stroke-current" />
        <span class="sr-only">Close</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
